import React from 'react'
import '../All_Section/CSS/College_card_Style.css';
import stars from '../Assets/icons/stars.svg';
import location from '../Assets/icons/location_2.svg'

const CollageCards = ({ imgsrc, heading, details, courses, id, clgLocation }) => {
  const handleClick = (e) => {
    e.preventDefault();
    const element = document.getElementById('contact us');
    element.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="college_info_container text-start" key={id}>
      <div class="card" >
        <img src={imgsrc} width='20px' height='auto' className='card-img-top' alt="" />
        <div class="card-body d-flex flex-column">
          <div className="card_first_row d-flex justify-content-between align-items-center">
            <p className='card_location d-flex align-items-center m-0'>
              <img src={location} className='mr-2' width='20' height='auto' alt="" />
              {clgLocation}
            </p>
            <img src={stars} alt="stars" />
          </div>
          <h5 class="card-title m-0">{heading}</h5>
          <div className="card_last_row d-flex justify-content-between align-items-center">
            <p class="card-text d-flex align-items-start pr-lg-5 m-0">
              <img src={location} className='mr-3' width='20' height='auto' alt="location" />
              {details}
            </p>
            <a href="#contact us" onClick={handleClick} class="btn btn-primary h-100">Enroll Now</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CollageCards;
