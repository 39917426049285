import React from 'react';
import { Container } from 'react-bootstrap';
import '../All_Section/CSS/Contact_us_Style.css';
import mail from '../Assets/icons/gmail.svg';
import phone from '../Assets/icons/phone_icon.svg';
import location from '../Assets/icons/location_1.svg';
import send from '../Assets/icons/send_icon.svg';
import { useState, useRef } from 'react'
import emailjs from "@emailjs/browser";

const Contact = () => {
  const formRef = useRef();
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    name: "",
    email: "",
    mobile: "",
    message: "",
  });

  const handleChange = (e) => {
    const { target } = e;
    const { name, value } = target;

    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    emailjs.send(
      'service_vqa9awr',
      'template_7le3gkp',
      {
        from_name: form.name,
        to_name: "Sudha Education",
        from_email: form.email,
        to_email: "sudhaeducation2023@gmail.com",
        message: form.message,
      },
      'ofy-qbKkxpW9n6RbA'
    )
      .then(
        () => {
          setLoading(false);
          alert("Thank you. We will get back soon");

          setForm({
            name: "",
            email: "",
            mobile: "",
            message: "",
          });
        },
        (error) => {
          setLoading(false);
          console.error(error);
          alert("please try again");
        }
      );

  };

  return (
    <section className='contact_us_section my-5' id='contact us'>
      <Container>
        <div className="row contact_us_row" >
          <div className="col-lg-6">
            <div className="contact_left_side">
              <h2>Let’s discuss on something cool together</h2>
              <div className="contact_details">
                <div className="contact_us_detail contact_mail_content">
                  <img src={mail} width='24' height='auto' alt="mail icon" />
                  <p>sudhaeducation@gmail.com</p>
                </div>
                <div className="contact_us_detail contact_phone_content">
                  <img src={phone} width='24' height='auto' alt="mail icon" />
                  <p>(+91) 63829-98277</p>
                </div>
                <div className="contact_us_detail contact_address_content">
                  <img src={location} width='24' height='auto' alt="mail icon" />
                  <p>182E, Arunagiri Uma Complex, S.N.High Road, Tirunelveli - 627001</p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="contact_form">
              <form ref={formRef}
                onSubmit={handleSubmit} action="submit">
                <div className="form_control_group">
                  <div className="form_control">
                    <input type='text' name='name' value={form.name} onChange={handleChange} placeholder='Your name' required />
                  </div>
                  <div className="form_control">
                    <input type='email' name='email' value={form.email} onChange={handleChange} placeholder='Your mail' required/>
                  </div>
                  <div className="form_control">
                    <input type='text' name='mobile' value={form.mobile} onChange={handleChange} placeholder='Your phone' required/>
                  </div>
                  <div className="form_control">
                    <input type='text' name="message" value={form.message} onChange={handleChange} placeholder='Your message' required />
                  </div>
                </div>

                <button  type='submit'><img src={send} width='18' height='auto' alt="" />{loading ? "Sending" : "Send message"}</button>
              </form>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
}

export default Contact;
