import React from 'react';
import { Container } from 'react-bootstrap';
import '../All_Section/CSS/Footer.css';
import logo from '../Assets/Logo/SE_word_logo.svg';
import mail from '../Assets/icons/gmail.svg';
import phone from '../Assets/icons/phone_icon.svg';
import location from '../Assets/icons/location_1.svg';

const Footer = () => {
    return (
        <section className='footer_section'>
            <Container>
                <div className="row">
                    <div className="col-lg-6 col-md-6">
                        <div className="footer_left_details">
                            <img src={logo} width='180' height='auto' alt="" />
                            <p>Veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. </p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                        <div className="footer_right_details">
                            <div className="row">
                                <div className="col-lg-6 col-md-6">
                                    <h3>Contact us</h3>
                                    <p><img src={phone} width='14' height='auto' alt="phone icon" /> (+91) 63829-98277</p>
                                    <p><img src={mail} width='14' height='auto' alt="mail icon" /> sudhaeducation@gmail.com</p>
                                </div>
                                <div className="col-lg-6 col-md-6 d-flex align-items-center">
                                    <p><img src={location} width='14' height='auto' alt="mail icon" /> 182E, Arunagiri Uma Complex, S.N.High Road, Tirunelveli - 627001</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </section>
    );
}

export default Footer;
