import React from 'react';
import { Container } from 'react-bootstrap';
import '../All_Section/CSS/About_Style.css';
import about_img from '../Assets/images/about_sec/about_img.svg';


const AboutSection = () => {
    return (
        <section className="about_section">
            <Container>
                <div className="row align-items-center mt-4">
                    <div className="col-12 col-md-6 col-lg-6">
                        <div className="about_text_container">
                            <p>Explore more...</p>
                            <h1>Why Choose Sudha Education?</h1>
                            <p>
                                vision of providing a support in identification and placement for the Students from India who prefer to pursue their Medical Educational goals in abroad, a sustainable and a better educational institute, and to provide the entire spectrum of their requirement, hassle free.
                            </p>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-lg-6">
                        <div className="about_img_container text-center">
                            <div className="aboub_img_background"></div>
                            <img src={about_img} width="300" height="auto" alt="doctor image" />
                        </div>
                    </div>
                </div>
            </Container>
        </section>
    );
}

export default AboutSection;
