import "./App.css";
import HeroSection from "./All_Section/Hero_section";
import Marquee from './Component/Slider_Component/Marquee';
import CollegeSection from "./All_Section/College_section";
// import ServicesSection from "./All_Section/Services_section";
import AboutSection from "./All_Section/About_section";
import Contact from "./Component/Contact";
// import AdmissionProcess from "./All_Section/Admission_process"; 
import Footer from "./Component/Footer";

function App() {
  return (
    <div className="App">
      <HeroSection />
      <Marquee />
      <CollegeSection />
      {/* <ServicesSection /> */}
      <AboutSection />
      <Contact />
      <Footer />
      

    </div>
  );
}

export default App;
