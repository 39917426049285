import React from 'react';
import '../All_Section/CSS/Navbar.css';
import logo from '../Assets/Logo/SE_word_logo.svg';
import facebook from '../Assets/icons/facebook.svg';
import whatsapp from '../Assets/icons/whatsapp.svg';
import instagram from '../Assets/icons/instagram.svg';

const Navbar = () => {

  return (
    <div className='d-flex justify-content-between align-items-center p-3'>
      <img src={logo} width='200' height='auto' alt="sudha education logo" />
      <div className="social_media_icon">
        <a  rel="noreferrer" href="https://www.instagram.com/sudhaeducation?igsh=OGQ5ZDc2ODk2ZA%3D%3D" target='_blank'>
          <img src={instagram} width='45' height='auto' alt="instagram icon" />
        </a>
        <a href="https://wa.me/9655546465" rel="noreferrer" target='_blank'>
          <img src={whatsapp} width='45' height='auto' alt="what's app icon" />
        </a>
        <a href="https://www.facebook.com/sudhaeducation2023?mibextid=qi2Omg&rdid=6j37OAeb13zKKXk4" rel="noreferrer" target='_blank'>
          <img src={facebook} width='45' height='auto' alt="facebook icon" />
        </a>

      </div>
    </div>
  );
}

export default Navbar;
