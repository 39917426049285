import React from "react";
import "../All_Section/CSS/Hero_section.css";
import Navbar from "../Component/Navbar";
import doc from "../Assets/images/doc_img.svg";

const HeroSection = () => {
  return (
    <section className="home_section_container">
      <div className="container">
        <Navbar />
        <div className="row align-items-center mt-4">
          <div className="col-12 col-md-6 col-lg-6">
            <div className="home_text_container">
              <p>Explore more...</p>
              <h1>ARE YOU PASSIONATE ABOUT THE FIELD OF MEDICINE?</h1>
              <p>
                Look no further! Our comprehensive medical course is designed to
                equip you with the knowledge, skills, and expertise needed to
                excel in this dynamic and rewarding profession.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-6">
            <div className="home_img_container text-center">
              <div className="doc_background"></div>
              <img src={doc} width="300" height="auto" alt="doctor image" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
