import React, { useEffect } from "react";
import "./Marquee.css";
import { newUpdate } from "../../Constant/index";

const Marquee = () => {
  useEffect(() => {
    // Check if the user has opted for reduced motion
    if (!window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
      addAnimation();
    }
  }, []);

  function addAnimation() {
    const scroller = document.querySelector(".scroller");
    scroller.setAttribute("data-animated", true);

    const scrollerInner = scroller.querySelector(".scroller__inner");
    const scrollerContent = Array.from(scrollerInner.children);

    // For each item in the array, clone it, add aria-hidden to it, and add it into the .scroller-inner
    scrollerContent.forEach((item) => {
      const duplicatedItem1 = item.cloneNode(true);
      duplicatedItem1.setAttribute("aria-hidden", true);
      scrollerInner.appendChild(duplicatedItem1);

      const duplicatedItem2 = item.cloneNode(true);
      duplicatedItem2.setAttribute("aria-hidden", true);
      scrollerInner.appendChild(duplicatedItem2);
    });
  }

  return (
    <section className="container new_slider_section">
      <h2>Updated News</h2>
      <div className="marquee-container my-5">
        <div className="scroller" data-speed="fast">
          <ul className="tag-list scroller__inner">
            {newUpdate.map((data) => (
              <li key={data.id}>{data.heading}</li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Marquee;
