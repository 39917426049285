// import clgeimg from '../Assets/images/college_img.webp';
import Flight_arrangement from '../Assets/Service_images/Flight_arrangement.webp';
import Visa_arrangement from '../Assets/Service_images/Visa_arrangement.webp';
import Provide_SIM from '../Assets/Service_images/Provide_SI.webp';
import Local_transport from '../Assets/Service_images/Local_transport.webp';
import Accommadation from '../Assets/Service_images/Accommadation.webp';
import Agent_assist from '../Assets/Service_images/Agent_assist.webp';
import clg1 from '../Assets/images/clg-1.png';
import clg2 from '../Assets/images/clg-2.png';


export const navlinks = [
    {
        id: 'home',
        title: 'Home'
    },
    {
        id: 'about',
        title: 'About'
    },
    {
        id: 'college info',
        title: 'College Info'
    },
    {
        id: 'services we offer',
        title: 'Service We Offer'
    },
    {
        id: 'contact us',
        title: 'Contact Us'
    }
];

export const newUpdate = [
    {
        id: 'news 1',
        heading: 'Next Batch on Seb'
    },
    {
        id: 'new 2',
        heading: 'Now Feb Batch Going On (UG / PG)'
    },
    {
        id: 'new 3',
        heading: 'Upcoming news'
    }
]

export const collegeDetails = [
    {
        id: 'college 1',
        collegeName: 'Jalal Abad State Medical University',
        collegeAddress: '57 Lenin Street Jalal-Abad, 715600, Kyrgyzstan',
        coursesAva: ['MBBS'],
        collegeImage: clg1,
        collegeLocation: 'Kyrgyzstan'
    },
    {
        id: 'college 2',
        collegeName: 'Yerevan Haybusak Medical University',
        collegeAddress: '6 Abelian St, Yerevan, Armenia',
        coursesAva: ['MBBS'],
        collegeImage: clg2,
        collegeLocation: 'Armenia'
    }
]

export const serviceWeOffer = [
    {
        id: 'Flight_arrangement',
        img: Flight_arrangement,
        serviceName: 'Flight Arrangement'
    },
    {
        id: 'Visa_arrangement',
        img: Visa_arrangement,
        serviceName: 'Visa Arrangement'
    },
    {
        id: 'Provide_SIM',
        img: Provide_SIM,
        serviceName: 'Provide SIM'
    },
    {
        id: 'Local_transport',
        img: Local_transport,
        serviceName: 'Local Transport'
    },
    {
        id: 'Accommadation',
        img: Accommadation,
        serviceName: 'Accommadation'
    },
    {
        id: 'Agent_assist',
        img: Agent_assist,
        serviceName: 'Agent Assist'
    }
]