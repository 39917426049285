import React from 'react';
import "../All_Section/CSS/Common_Style.css";
import '../All_Section/CSS/College_Section_Style.css';
import { Container } from 'react-bootstrap';
import { collegeDetails } from '../Constant/index';

import CollageCards from "../Component/CollageCards";

const CollegeSection = () => {
    return (
        <div className='college_card_info_section py-5' id='college info'>
            <Container>
                <div className="college_heading_container d-flex justify-content-center">
                    <h2>Popular Colleges</h2>
                    {/* <div className="college_country_menu d-flex justify-content-between align-items-center px-5">
                        <p>All</p>
                        <p>kyrgyzstan</p>
                        <p>Armenia</p>
                    </div> */}
                </div>
                <div className="college_card_component my-5">
                    {collegeDetails.map((data) => (
                        <CollageCards imgsrc={data.collegeImage} heading={data.collegeName} details={data.collegeAddress} courses={data.coursesAva} clgLocation={data.collegeLocation} id={data.id} />
                    ))}
                    {/* <CollageCards /> */}
                </div>
            </Container>
        </div>
    );
}

export default CollegeSection;
